import React from 'react';
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import emptyImg from '../../img/empty/tag.png'
import './styles.scss'

const MenuItem = ({
  to = '#',
  onClick = () => {},
  text = '',
  danger = false,
  count = 0,
  preferences = [],
}) => (
  <Link to={to} className={clsx('menu-item', danger && 'danger')} onClick={onClick}>
    <span className="menu-item__text">{text}</span>
    {count ? (
        <span className="menu-item__count">{count > 9 ? '9+' : count}</span>
    ) : null}
    {preferences.length ? (
        <div className="menu-item__preferences">
            {preferences.map(({ image, name, tagId }) => (
                <span className="menu-item__icon" key={tagId}>
                    <img src={image || emptyImg} alt={name} />
                </span>
            ))}
        </div>
    ) : null}
  </Link>
)

const Menu = ({ onShowModalLogout, preferences, activeOrderCount = 0 }) => (
  <ul className="menu">
    <li>
      <MenuItem to="/Orders" text="Заказы" count={activeOrderCount}/>
    </li>
    {/* <li>
      <MenuItem to="/Preferences" text="Предпочтения" preferences={preferences} />
    </li> */}
    <li>
      <MenuItem onClick={onShowModalLogout} text="Выйти" danger />
    </li>
  </ul>
)

export default Menu;
