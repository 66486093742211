import React, { useCallback, useEffect, useState, useRef } from 'react'
import {Button} from 'react-bootstrap'
import { useDispatch } from "react-redux";
import clsx from 'clsx'
import API from "../../api";
import Modal from '../modal'
import Input from '../input/index'
import {setUserInfo} from "../../store/actions/user";
import { INITIAL_SEC_RESEND_CODE } from "../../constants/code";
import {useReducer} from "../../util/store";
import {getClearPhone, checkPhoneByLength} from "../../util/phone";
import './styles.scss'

const sendCode = (telephone) => new Promise((resolve, reject) => {
  API.post(
    '/user',
    { telephone },
    resolve,
    reject,
    // { isHideAlert: true }
  );
})

const editPhone = (telephone, code) => new Promise((resolve, reject) => {
  API.post(
    '/user',
    { telephone, code },
    resolve,
    reject,
    // { isHideAlert: true }
  );
})

const ResendCode = ({ resendCode, isRepeat, toggleRepeat, disabled }) => {
  const [resend, setResend] = useState(INITIAL_SEC_RESEND_CODE);

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }

    setResend(INITIAL_SEC_RESEND_CODE)
    resendCode()
  }, [resendCode, disabled])

  useEffect(() => {
    let timer = null
    if (isRepeat) {
      return () => clearTimeout(timer)
    }

    clearTimeout(timer)
    if (resend === 0) {
      toggleRepeat(true)
    }
    timer = setTimeout(() => {
      const newResend = resend - 1
      if (newResend === 0) {
        toggleRepeat(true)
      }
      setResend(newResend)
    }, 1000)

    return () => clearTimeout(timer)
  }, [isRepeat, toggleRepeat, resend])

  return (
    <div className="edit-phone__resend">
      {isRepeat ? (
        // <Button disabled={disabled} variant="gray-border" type="button" onClick={onClick}>Отправить новый код</Button>
        <div className={clsx('edit-phone__resend-btn', disabled && '_disabled')} onClick={onClick}>Отправить новый код</div>
      ) : (
        <p className="edit-phone__resend-text">Получить новый код можно через 00:{`${resend}`.padStart(2, '0')}</p>
      )}
    </div>
  )
}

const ModalEditPhone = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [alert, setAlert] = useReducer('alert');
  const refCode = useRef(null)
  const refForm = useRef(null)
  const [loading, toggleLoading] = useState(false)
  const [visibleCode, setVisibleCode] = useState(false)
  const [phone, setPhone] = useState({ value: '', oldValue: '', error: '' })
  const [code, setCode] = useState({ value: '', error: '' })
  const [isRepeat, toggleRepeat] = useState(false);

  const resendCode = useCallback(async (e) => {
    const clearPhone = getClearPhone(phone.value)

    if (!checkPhoneByLength(clearPhone)) {
      setPhone((prev) => ({ ...prev, error: 'Заполните номер телефона' }))
      return
    }

    toggleRepeat(false)

    try {
      setPhone((prev) => ({ ...prev, oldValue: prev.value }))
      const res = await sendCode(clearPhone)
      if(res.code){
        setAlert({danger: res.message})
        return
      }
      if(res.message){
        setAlert({'info': res.message})
        return
      }
    } catch (error) {
      console.log(error)
    }
  }, [phone])

  const onSave = useCallback(async (e) => {
    e.preventDefault();
    if (loading) {
      return
    }

    const clearPhone = getClearPhone(phone.value)
    if (!checkPhoneByLength(clearPhone)) {
      setPhone((prev) => ({ ...prev, error: 'Заполните номер телефона' }))
      return
    }

    // Если поле для кода скрыто или изменился телефон, отправляем только телефон для отправки кода
    if (!visibleCode || phone.value !== phone.oldValue) {
      try {
        toggleLoading(true)
        setPhone((prev) => ({ ...prev, oldValue: prev.value }))
        const res = await sendCode(clearPhone)
        if(res.code){
          setAlert({danger: res.message})
          return
        }
        if(res.message){
          setAlert({'info': res.message})
          setVisibleCode(true)
          return
        }
      } catch (error) {
        console.log(error)
      } finally {
        toggleLoading(false)
      }

      return
    }

    const codeStr = code.value
    if (codeStr.length === 0) {
      setCode((prev) => ({ ...prev, error: 'Введите код из sms' }))
      return
    }

    // Отправка телефона и кода из смс для привязки телефона
    try {
      toggleLoading(true)
      const res = await editPhone(clearPhone, codeStr)
      if(res.code){
        setAlert({danger: res.message})
        return
      }
      if(res.telephone){
        // Генерим новый токен
        API.login(res.telephone, codeStr);
        setAlert({success: 'Сохранено'});

        const obj = { ...res, code: codeStr, isTemporary: false, }
        dispatch(setUserInfo(obj))
        onClose()
        return
      }
    } catch (error) {
      console.log(error)
    } finally {
      toggleLoading(false)
    }
  }, [phone, code, visibleCode, dispatch, onClose, loading])

  const onChangePhone = useCallback((e) => {
    setPhone({ value: e.target.value, error: '' })
  }, [])
  const onChangeCode = useCallback((e) => {
    setCode({ value: e.target.value, error: '' })
  }, [])

  useEffect(() => {
    const flag = 'OTPCredential' in window
    if (!flag) {
      return
    }

    const input = refCode.current
    const form = refForm.current
    if (!input) return;
    // Cancel the WebOTP API if the form is submitted manually.
    const hasAbortController = !!window?.AbortController
    const ac = hasAbortController ? new AbortController() : null;

    if (form) {
      form.addEventListener('submit', e => {
        // Cancel the WebOTP API.
        if (hasAbortController) {
          ac?.abort();
        }
      });
    }
    const body = {
      otp: { transport:['sms'] },
    }

    if (hasAbortController) {
      body.signal = ac?.signal
    }
    // Invoke the WebOTP API
    navigator.credentials.get(body).then(otp => {
      setCode({ value: otp.code, error: '' })
      // Automatically submit the form when an OTP is obtained.
      if (form) {
        form.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    }).catch(err => {
      console.log(err);
    });

    return () => {
      if (hasAbortController) ac?.abort()
    }
  }, [])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Подтвердите телефон"
      mod="cart"
      onSubmit={false}
    >
      <div className="edit-phone">
        <form onSubmit={onSave} method="post" disabled={loading} ref={refForm}>
          <div className="">
            <div className="edit-phone__field">
              <Input
                id="telephone"
                name="telephone"
                mask="+7(\999)-999-99-99"
                onChange={onChangePhone}
                value={phone.value}
                placeholder="+7(___)-___-__-__"
                isMask
                label="Номер телефона"
                error={phone.error}
                inputMode="numeric"
                type="tel"
                disabled={loading}
              />
            </div>
            {visibleCode ? (
              <ResendCode
                resendCode={resendCode}
                isRepeat={isRepeat}
                toggleRepeat={toggleRepeat}
                disabled={loading}
              />
            ) : null}
            {visibleCode ? (
              <div className="edit-phone__field">
                <Input
                  onChange={onChangeCode}
                  name="sms"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  placeholder="Code"
                  label="Код"
                  value={code.value}
                  error={code.error}
                  disabled={loading}
                  ref={refCode}
                />
              </div>
            ) : null}
          </div>
          <div className="edit-phone__btn">
            <Button disabled={loading} variant="primary" type="submit">{visibleCode ? 'Подтвердить' : 'Отправить код'}</Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalEditPhone
