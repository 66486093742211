import React, { useEffect, useState, useRef, useCallback } from 'react'
import clsx from 'clsx'
import { slideDown, slideUp } from '../../util/collapse/animation'
import './styles.scss'

const Collapse = ({
  title = '',
  subtitle = '',
  mod,
  duration = 300,
  children,
}) => {
  const timer = useRef(null)
  const isMounted = useRef(false)
  const refBody = useRef(null)
  const refRoot = useRef(null)
  const refContent = useRef(null)
  const [open, setOpen] = useState(false)

  const onToggle = useCallback(() => {
    setOpen(openValue => !openValue)
  }, [])

  useEffect(() => {
    if (!refBody.current || !isMounted.current) {
      return
    }

    if (open) {
      slideDown(refBody.current, duration)

      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        const rect = refRoot.current.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        window.scrollTo({ top: rect.top + scrollTop, left: 0, behavior: 'auto' })
      }, duration + 10)
    } else {
      slideUp(refBody.current, duration)
    }

    return () => clearTimeout(timer.current)
  }, [open, duration])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const onClickHeader = useCallback(() => {
    onToggle()
  }, [onToggle])

  return (
    <div className={clsx('collapse-block', mod, open && 'open')} ref={refRoot}>
      <div className="collapse-block__header" onClick={onClickHeader}>
        <p className="collapse-block__header-text">
          {title}
          {subtitle ? <span className="collapse-block__header-note">{subtitle}</span> : null}
        </p>
        <div className="collapse-block__header-icon" />
      </div>

      <div className="collapse-block__body" ref={refBody}>
        <div className="collapse-block__content" ref={refContent}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Collapse
