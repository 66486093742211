import { useEffect, useRef } from 'react'
import _throttle from 'lodash/throttle'
import { CHANGE_LAYOUT, CHANGE_LAYOUT_PADDING_BOTTOM } from '../../constants/events'

const useLayout = (changed = false) => {
  const paddingTop = useRef(0)
  const paddingBottom = useRef(0)

  useEffect(() => {
    const layout = document.querySelector('.layout')
    if (!layout) {
      return
    }

    const top = layout.querySelector('.layout__top')
    const body = layout.querySelector('.layout__body')
    const bottom = layout.querySelector('.layout__bottom')

    if (!body) {
      return
    }

    const fixPaddingLayout = () => {
      if (top) {
        const heightTop = top.getBoundingClientRect().height
        if (paddingTop.current !== heightTop) {
          paddingTop.current = heightTop
          body.style['padding-top'] = heightTop + 'px'
        }
      }

      if (bottom) {
        const heightBottom = bottom.getBoundingClientRect().height
        if (paddingBottom.current !== heightBottom) {
          paddingBottom.current = heightBottom
          body.style['padding-bottom'] = heightBottom + 'px'
          const event = new CustomEvent(CHANGE_LAYOUT_PADDING_BOTTOM, { detail: { heightBottom } })
          window.dispatchEvent(event);
        }
      }
    }

    const fixPaddingLayoutThrottle = _throttle(fixPaddingLayout, 100)
    fixPaddingLayout()
    window.addEventListener('resize', fixPaddingLayoutThrottle)
    window.addEventListener(CHANGE_LAYOUT, fixPaddingLayoutThrottle)
    return () => {
      window.removeEventListener('resize', fixPaddingLayoutThrottle)
      window.removeEventListener(CHANGE_LAYOUT, fixPaddingLayoutThrottle)
    }
  }, [changed])
}

export default useLayout
