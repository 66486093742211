import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { logoutUser } from '../actions/user'
import { getUser, editUser } from '../../query/user'

const defaultData = {
  'default': true,
  'firstname': 'Аноним',
  'telephone': ' _ _ _ _ _ _ _ _',
  'address': ' _ _ _ _ _ _ _ _ _ _ _ _',
  'reward': 0,
  'orderCount': 0,
  'referal': {},
  email: null,
  isTemporary: true,
}

const initialState = {
  data: {
    address: '',
    firstname: '',
    telephone: '',
    orderCount: 0,
    email: null,
    referal: {
      code: null, reward: null,
    },
    reward: 0,
    tagsCount: 0,
    code: null,
    isTemporary: true
  },
  guest: {
    phone: '',
  },
  isAuth: false,
  token: null,
}

const getPromiseUser = () => {
  return new Promise((resolve, reject) => {
    getUser(resolve, reject)
  })
}

export const getInfoUser = createAsyncThunk(
  'user/getInfoUser',
  async (_, { getState }) => {
    const res = await getPromiseUser()
    return res
  },
)

export const editInfoUser = createAsyncThunk(
  'user/editInfoUser',
  async (body, { getState }) => {
    const res = await editUser(body)
    return res
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        guest: { ...initialState.guest },
        isAuth: true,
      }
    },
    setUserToken(state, action) {
      return {
        ...state,
        token: action.payload,
        isAuth: true,
      }
    },
    // TODO: temp hide
    setUserTemp(state, action) {
      const { token, telephone, password: code } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          telephone: initialState.data.telephone,
          code,
        },
        token: token,
        isAuth: true,
      }
    },
    setGuest(state, action) {
      return {
        ...state,
        guest: action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getInfoUser.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    })
    builder.addCase(editInfoUser.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    })
    builder.addCase(logoutUser, (state, action) => {
      return initialState
    })
  },
})

export const { setUserInfo, setUserToken, setGuest, setUserTemp } = userSlice.actions
export default userSlice.reducer
