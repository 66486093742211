import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { setInfoCart } from '../../store/actions/cart'
import { cartInfoSelector } from '../../store/selectors/cart'
import { CardOptions, CardModifiers } from '../../components/card-option'
import Logo from '../../components/logo'
import CartBtn from '../../components/cart-btn'
import Image from '../../components/image'
import { defaultProduct, getProduct } from '../../query/products'
import Collapse from '../../components/collapse'
import CardBase from '../../components/card-base'
import { HeadNested } from '../../components/head'
import { getFormattedPrice } from '../../util/number'
import useLayout from "../../util/layout";
import { getAllOptions, getDefaultOptions, getDefaultActiveBase, getActiveBasesStringList, getActiveModifiersStringList, getDefaultStateOptions } from "../../util/product/options"
import { getAllPrice } from "../../util/product/price"
import { getListsOptionsFromWishes, SERVE_COLD_ID, SERVE_HOT_ID } from "../../util/product/wishes"
import { getAllStatsDetailsProduct } from '../../util/product/stats'
import { SIZE_LIST, SIZE_DEFAULT } from "../../constants/product"
import { MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP } from '../../constants/modifiers'
import IconPrev from '../../img/icons/prev-arrow.svg'
import emptyImg from '../../img/empty/product-details.png'
import './product.scss'

const Product = () => {
  useLayout()
  const commentRef = useRef('')
  const dispatch = useDispatch()
  const history = useHistory()
  const cartData = useSelector(cartInfoSelector)
  const [product, setProduct] = useState(defaultProduct)
  const [options, setOptions] = useState({})
  const {alias} = useParams()
  const [cartQuantity, setCartQuantity] = useState(0)

  useEffect(()=>{
    let qty = cartData.products.find(item => item.productId === product.id)
    if (typeof qty !=='undefined' && cartQuantity !== qty.quantity) {
      setCartQuantity(qty.quantity)
    }
  }, [])

  const [openFeat, setOpenFeat] = useState(false)
  const [activeBase, setActiveBase] = useState({})
  const [initialListsOptions, setInitialListsOptions] = useState({
    wishes: { items: [] },
    serving: { items: [] },
    taste: { items: [] },
  })

  const [stateOptions, setStateOptions] = useState({
    size: SIZE_DEFAULT,
    cookTypes: null,
    wishes: null,
    serving: null,
    taste: [],
  })

  const handlersOptions = useMemo(() => {
    const SERVE_HOT = initialListsOptions.serving.items.find(({ code }) => code === SERVE_HOT_ID)
    return {
      onChangeSize: (val) => setStateOptions((prev) => ({ ...prev, size: val })),
      onChangeCookTypes: (val) => {
        setStateOptions((prev) => {
          // Нажатие на любой из способ приготовления переводит подача горячим в состояние 1
          if (val) {
            return { ...prev, cookTypes: val, serving: SERVE_HOT }
          }
          return { ...prev, cookTypes: val }
        })
      },
      onChangeWishes: (val) => setStateOptions((prev) => ({ ...prev, wishes: val })),
      onChangeServing: (val) => {
        setStateOptions((prev) => {
          // Нажатие на подать холодным - переводит все из способ приготовления в значение 0
          if (val && val.code === SERVE_COLD_ID) {
            return ({ ...prev, serving: val, cookTypes: null })
          }

          // Если у нас выбрано Подать горячим и есть выбранный Способ приготовления, то нельзя отжать Подать горячим
          if (!val && prev.serving?.code === SERVE_HOT_ID && prev.cookTypes) {
            return prev
          }

          return ({ ...prev, serving: val })
        })
      },
      onChangeTaste: (val) => {
        setStateOptions((prev) => {
          const index = prev.taste.findIndex(({ option_value_id }) => option_value_id === val.option_value_id)
          if (index === -1) {
            // Добавляем опцию
            return { ...prev, taste: [...prev.taste, val] }
          }

          // Удаляем опцию по индексу
          return { ...prev, taste: [...prev.taste.slice(0, index), ...prev.taste.slice(index + 1)] }
        })
      },
    }
  }, [initialListsOptions.serving.items])

  const featuresInf = () => {
    setOpenFeat(!openFeat)
  }

  useEffect(() => {
    getProduct(alias, (product) => {
      try {
        const defaultOptions = getDefaultOptions(product)
        const defaultActiveBase = getDefaultActiveBase(product)
        // TODO: костыль
        const lists = getListsOptionsFromWishes(product.wishes)
        const defaultStateOptions = getDefaultStateOptions(product.cooktypes, lists)

        setOptions(defaultOptions)
        setActiveBase(defaultActiveBase)
        setStateOptions(defaultStateOptions)
        setInitialListsOptions(lists)

        setProduct(product)
      } catch (error) {
        console.log('error')
      }

    },
    (res) => {
      if (res.code === 404) {
        history.replace('/404')
      }
    }, { useCache: true })
  },[alias])

  const productCart = useMemo(() => {
    const basePrice = parseInt(product.price)
    const priceAllModifiers = getAllPrice(product, options, activeBase, stateOptions.size)
    // Цена товара + выбранные модификаторы, основы, размер

    const totalPrice = basePrice + priceAllModifiers

    return {
      totalPrice,
      priceAllModifiers,
      basePrice,
      data: {
        price: totalPrice,
        productId: product.id,
        quantity: cartQuantity + 1,
        options: getAllOptions({
          product, options, activeBase, activeOptions: stateOptions, initialListsOptions
        })
      }
    }
  }, [product, options, cartQuantity, activeBase, stateOptions, initialListsOptions])

  const addCart = () => {
    const comment = commentRef.current
    dispatch(setInfoCart({products: [productCart.data]}))
    setCartQuantity(productCart.data.quantity)
  }

  const onChangeBase = useCallback((id, item) => {
    setActiveBase((prev) => ({ ...prev, [id]: item }))
  }, [])

  const allCountModifiers = useMemo(() => {
    if (!product?.ingredients?.length) {
      return ''
    }

    // Выбранные модификаторы со всех групп
    const selected = Object.values(options).reduce((all, list) => (
      all + list.reduce((sum, { quantity }) => sum + quantity, 0)
    ), 0)

    // Максимальное кол-во модификаторов: кол-во групп умноженное на max одной группы (5)
    const max = product.ingredients.length * MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP

    return `${selected}/${max}`
  }, [options, product?.ingredients?.length])

  const onChangeComment = useCallback((value) => {
    commentRef.current = value
  }, [])

  const composition = useMemo(() => {
    const listActiveBase = getActiveBasesStringList(activeBase, stateOptions.size)
    const listActiveModifiers = getActiveModifiersStringList(options, product?.ingredients)

    const allList = [...listActiveBase, ...listActiveModifiers]

    return allList
  }, [stateOptions.size, activeBase, options, product?.ingredients])

  const stats = useMemo(() => getAllStatsDetailsProduct(composition), [composition])

  const hideBlockWithOptions = useMemo(() => {
    const { wishes, serving, taste } = initialListsOptions
    return product.basics.length === 0 && wishes.items.length === 0 && serving.items.length === 0 && taste.items.length === 0
  }, [product.basics, initialListsOptions])
  return (
    <div className={clsx('layout', 'product', product.default && 'loadData')}>
      <HeadNested page="product" name={product.name} />
      <div className="layout__body layout__body--pt-0">
        <div className="grid-inner">

          <div className="product__top">
            <div className="product__top-btn" onClick={history.goBack}>
              <IconPrev/>
            </div>
            <div className="product__top-logo">
              <Logo />
            </div>
            {/* <div className="product__top-btn" onClick={history.goBack}>
              <IconClose />
            </div> */}
          </div>

          <div className="product-block product-block--image">
            <div className="product__image">
              <Image src={product.image} alt={product.name} emptyImg={emptyImg} />
            </div>
          </div>

          <div className="product-block">
            <div className="product-info">
              <div className="product-info__top">
                <p className="product-info__name">{product.name}</p>
                <p className="product-info__price">
                  <span>{getFormattedPrice(productCart.basePrice)} ₽</span>
                  {productCart.priceAllModifiers ? (
                    <span className="product-info__price-all-modifiers">+{getFormattedPrice(productCart.priceAllModifiers)} ₽</span>
                  ) : null}
                </p>
              </div>
              {/* {product.description ? (
                <p className="product-info__description">
                  <Html>{product.description}</Html>
                </p>
              ) : null} */}
              {stats.length ? (
                <div className="product-stats">
                  {stats.map((attribute) => (
                    <div className={clsx('product-stats__item', attribute.active && '_active')} key={attribute.name}>
                      <div className="product-stats__item-value">{attribute.value}</div>
                      <div className="product-stats__item-label">{attribute.name}</div>
                    </div>
                  ))}
                </div>
              ) : null}
              {composition.length ? (
                <div className="product-compound">
                  <p className="product-compound__title">Состав</p>
                  <p className="product-compound__notes">Продукт, кол-во, вес, калории, белки, жиры, углеводы</p>
                  {composition.map(({ label, stats }) => (
                    <p className="product-compound__text" key={label}>
                      {label}
                      {stats ? <span className="product-compound__stats">{stats}</span> : null}
                    </p>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          {!hideBlockWithOptions ? (
            <Collapse title="Настройки" mod="product__options">
              <CardOptions
                cookTypes={product.cooktypes}
                wishes={initialListsOptions.wishes}
                serving={initialListsOptions.serving}
                taste={initialListsOptions.taste}
                sizeList={SIZE_LIST}
                activeOptions={stateOptions}
                handlers={handlersOptions}
                onChangeComment={onChangeComment}
                hideSizes={product.basics.length === 0}
              />
            </Collapse>
          ) : null}

          {product.basics.length ? (
            <Collapse title="Основа" mod="product__base" duration={150}>
              <CardBase activeBase={activeBase} activeSize={stateOptions.size} list={product.basics} onChange={onChangeBase} />
            </Collapse>
          ) : null}

          {product.ingredients.length ? (
            <Collapse title="Модификаторы" subtitle={allCountModifiers} mod="product__mods">
              <CardModifiers options={options} setOptions={setOptions} data={product} />
            </Collapse>
          ) : null}
        </div>
      </div>

      <div className="layout__bottom">
        <div className="grid-inner">
          <Button onClick={addCart} variant="primary">Добавить в корзину - {getFormattedPrice(productCart.totalPrice)}₽</Button>
        </div>
        <CartBtn />
      </div>
    </div>
  )
}

export default Product
