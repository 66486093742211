import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Image from '../image'
import { getBackgroundColor } from '../../util/catalog/color'
import emptyImg from '../../img/empty/catalog.png'

import './styles.scss'

const CatalogItem = ({ data, base: baseUrl = '' }) => {
  const isDefault = useMemo(() => !!data.default, [data.default])
  const link = useMemo(() => {
    const base = data.type === 'category' ?  `${baseUrl}/` : '/Subcategory/'
    return `${base}${data.alias}`
  }, [data])

  const styles = useMemo(() => {
    const image = (!data.default && data.image) ? data.image : emptyImg
    return {
      backgroundImage: `url(${image})`,
      backgroundColor: getBackgroundColor(data),
    }
  }, [data.image])

  const linkBlock = data.disabled ? (
    <div className={clsx('catalog-card__link')} style={styles}>
      <p className="catalog-card__name catalog-card__name--disabled">{data.name} <br /><span className="catalog-card__note">Скоро</span></p>
    </div>
  ) : (
    isDefault ? (
      <div className={clsx('catalog-card__link')} style={styles}>
        <p className="catalog-card__name">{data.name}</p>
      </div>
    ) : (
      <Link className={clsx('catalog-card__link')} to={link} style={styles}>
        <p className="catalog-card__name">{data.name}</p>
      </Link>
    )
  )

  return (
    <div className={clsx('catalog-card', isDefault && 'loadData')}>
      <div className="catalog-card__inner">{linkBlock}</div>
    </div>
  )
}

export default CatalogItem
