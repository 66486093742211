import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import _throttle from 'lodash/throttle'
import { nonBreakingSpace } from "../../../util/product/stats"
import { roundedNum } from "../../../util/number"
import './styles.scss'

const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    const scrollThrottle = _throttle(callback, 100)
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', scrollThrottle)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', scrollThrottle)
    };
  }, [ref, callback]);
}

const TooltipBody = ({ onHide, refParent, data = [] }) => {
  const [show, setShow] = useState(false)
  useOutsideAlerter(refParent, onHide)

  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <div className={clsx('tooltip-stats__body', show && '_active')} onClick={onHide}>
      <div className="tooltip-stats__arrow" />
      <div className="tooltip-stats__content">
        <div className="tooltip-stats__caption">Пищевая ценность</div>
        {data.map(({ label, value, postfix }) => (
          <div className="tooltip-stats__row" key={label}>
            <p className="tooltip-stats__label">{label}</p>
            <p className="tooltip-stats__value">{value}{nonBreakingSpace}{postfix}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const MAX_COUNT_IN_SINGLE_ROW = 3

const HORIZONTAL_POSITIONS = {
  0: '_right',
  1: '_left',
  2: '_center',
  3: '_right',
}

const getHorizontalPositionByIndex = (index) => {
  if (index <= MAX_COUNT_IN_SINGLE_ROW) {
    return HORIZONTAL_POSITIONS[index]
  }

  const diff = index % MAX_COUNT_IN_SINGLE_ROW
  return HORIZONTAL_POSITIONS[diff] || HORIZONTAL_POSITIONS[0]
}

const getValueNutritionBy100gram = (value = 0, weight = 0) => {
  if (!value || !weight) {
    return 0
  }

  return roundedNum((value * 100 / weight))
}

const Tooltip = ({
  option,
  index,
  mods = '',
}) => {
  const ref = useRef(null)
  const [data, setData] = useState({ show: false, position: '_top' })
  const onClick = useCallback((e) => {
    e.stopPropagation()
    const top = ref.current.getBoundingClientRect().top
    setData((prev) => ({ show: !prev.show, position: top < 140 ? '_bottom' : '_top' }))
  }, [])
  const onHide = useCallback((e) => {
    e?.stopPropagation()
    setData({ show: false, position: '_top' })
  }, [])

  const stats = useMemo(() => {
    const { energy_full, proteins_full, fat_full, carbohydrates_full } = option.nutrition

    const data = [
      { label: 'Энерг. ценность', value: roundedNum(energy_full), postfix: 'кКал' },
      { label: 'Белки', value: roundedNum(proteins_full), postfix: 'г' },
      { label: 'Жиры', value: roundedNum(fat_full), postfix: 'г' },
      { label: 'Углеводы', value: roundedNum(carbohydrates_full),  postfix: 'г' },
    ]

    return data.filter(({ value }) => value)
  }, [option.nutrition])

  const horizontalPosition = useMemo(() => getHorizontalPositionByIndex(index + 1), [index])

  if (stats.length === 0) {
    return null
  }

  return (
    <div className={clsx('tooltip-stats', mods, data.position, horizontalPosition, data.show && '_show')} ref={ref}>
      <div className="tooltip-stats__btn" onClick={onClick} />
      {data.show ? (
        <TooltipBody onHide={onHide} refParent={ref} data={stats}/>
      ) : null}
    </div>
  )
}

export default Tooltip
