import React, { useEffect, Fragment, forwardRef, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductCard from '../product-card'
import { getInfoCart } from '../../store/actions/cart'
import { cartInfoSelector } from '../../store/selectors/cart'
import './styles.scss'

const sub = (categories, categoryId) => {
    let cat = {};
    for (let category of categories || []) {
        if(category.id === categoryId){
            cat = category;
            break;
        }
    }
    return cat;
}

const getGroups = (products, categories) => {
    const checked = {}
    const list = []
    products.forEach((item) => {
        const check = checked[item.categoryId]
        if (check) {
            list[check.index].products.push(item)
        } else {
            list.push({
                category: sub(categories, item.categoryId),
                products: [item]
            })

            checked[item.categoryId] = { index: list.length - 1 }
        }
    })

    return list
}

const ProductList = forwardRef(({
    data,
    categories
}, ref) => {
    const groups = useMemo(() => getGroups(data, categories), [categories, data])
    // const dispatch = useDispatch()
    const cartData = useSelector(cartInfoSelector)

    // useEffect(() =>{
    //     // TODO: вроде лишний запрос на корзину!!!
    //     dispatch(getInfoCart({ useCache: true }))
    // }, [dispatch])

    return (
        <div className="product-list" ref={ref}>
            {groups.map(({ category, products }, index) => (
                <Fragment key={`${category.id}__${index}`}>
                    {category ? (
                        <div
                            className="product-list__caption text"
                            data-section-id={category.alias}
                            key={category.id}
                        >{category.name}</div>
                    ) : null}
                    {products.map((product) => (
                        <div className="product-list__item" key={product.id}>
                            <ProductCard data={product} cartData={cartData} />
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    );
});

export default ProductList
