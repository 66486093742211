import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import {setInfoCart} from '../../store/actions/cart'
import { getAllOptions, getDefaultOptions, getDefaultActiveBase } from "../../util/product/options"
import Image from '../image'
import { SIZE_DEFAULT } from "../../constants/product"
import dishesImg from '../../img/empty/catalog-item.png'
import './styles.scss'

const ProductCard = ({
    data: product,
    cartData,
}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(0);
    const [loading, setLoading] = useState(false);
    const isDefaultProduct = useMemo(() => !!product.defaultProduct, [product.defaultProduct])
    const href = useMemo(() => isDefaultProduct ? '#' : '/Product/' + product.id, [product.id, isDefaultProduct])

    useEffect(()=>{
        const currentProduct = cartData.products.find(({ productId }) => productId === product.id);
        if (!currentProduct) {
            setQuantity(0)
            return
        }
        if (quantity !== currentProduct.quantity){
            setQuantity(currentProduct.quantity);
        }
    }, [cartData.products]);

    const changeQuantity = useCallback(async (plus) => {
        // if(product.isRequiredOption){
        //     history.push(href);
        // }

        const count = plus ? quantity + 1 : quantity - 1
        setQuantity(count);

        setLoading(true)
        try {
            const defaultOptions = getDefaultOptions(product)
            const defaultActiveBase = getDefaultActiveBase(product)

            const options = getAllOptions({
                product,
                options: defaultOptions,
                activeBase: defaultActiveBase,
                activeOptions: {
                  size: SIZE_DEFAULT,
                  cookTypes: null,
                  wishes: null,
                  serving: null,
                  taste: [],
                },
            })
            await dispatch(setInfoCart({products: [{ productId: product.id, quantity: count, options, withDefaultOptions: true }]}))
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }, [product, quantity])

    const renderOldPrice = useMemo(() => (
        product.oldPrice ? `${parseInt((product.price/product.oldPrice - 1)*100)}%` : ''
    ), [product.oldPrice, product.price])

    const onClickQuantity = useCallback(() => {
        if (isDefaultProduct) {
            return
        }

        changeQuantity(true)
    }, [isDefaultProduct])

    const onClick = useCallback(() => history.push('/Cart'), [])

    return (
        <div className={"product-card " + (product.default ? 'loadData' : '')}>
            {!isDefaultProduct ? <Link className="abs-link abs-link--overlay" to={href}/> : null}
            <div className="product-card__img">
              <Image src={product.image} alt="" emptyImg={dishesImg} />
              {/* <div className="statusOrder">
                {product.oldPrice ? <div className="statusOrder__item statusOrder-discount">{renderOldPrice}</div> : null}
              </div> */}
            </div>
            {/* TODO: после доработки убрать index в качестве key */}
            <div className="product-card__badges statusOrder">
              {(product?.stiker?.image ? [product.stiker] : []).map((item, index) => (
                <div key={item.id || index} className="product-card__badge product-card__badge--sticker">
                  <img src={item.image} alt=""/>
                </div>
              ))}
              {/* {[
                { id: 1, image: 'https://tarelka.in-aim.dev/image/cache/no_image-600x600.png' },
                { id: 2, image: 'https://tarelka.in-aim.dev/image/cache/no_image-600x600.png' },
                { id: 3, image: 'https://tarelka.in-aim.dev/image/cache/no_image-600x600.png' },
              ].map((item) => (
                <div key={item.id} className="product-card__badge product-card__badge--sticker">
                  <img src={item.image} alt=""/>
                </div>
              ))} */}
              {(product?.statusOrder ? product.statusOrder : []).map((item, index) => (
                <div
                  key={item.id || index}
                  className={clsx('product-card__badge', 'product-card__badge--status', `product-card__badge-${item.class}`)}
                >
                  {item.text}
                </div>
              ))}
              {/* {[
                  { id: 1, text: '1+1', class: '-combo' },
                  { id: 2, text: '-45%', class: '-discount' },
                  { id: 3, text: 'new', class: '-new' },
              ].map((item) => (
                <div key={item.id} className={clsx('product-card__badge', 'product-card__badge--status', `product-card__badge-${item.class}`)}>
                  {item.text}
                </div>
              ))} */}
            </div>
            <div className="product-card__body">
                <div className="product-card__name text">{product.name}</div>
            </div>
            <div className="product-card__bottom">
                {quantity ? (
                    <div className="product-card__cart" onClick={onClick}>В корзине</div>
                ) : (
                    <div className="product-card__price text" onClick={onClickQuantity}>
                        {/* <div className="old">{product.oldPrice}</div> */}
                        <div className="price">{product.priceWithModifiers} ₽</div>
                        <div className="plus">+</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCard;
