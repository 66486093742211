import React, {useCallback, useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../components/input/index";
import TopPage from "../../components/top-page";
import Checkbox from "../../components/checkbox";
import { guestPhoneSelector } from "../../store/selectors/user";
import {setGuest} from "../../store/actions/user";
import useLayout from "../../util/layout";
import {useReducer} from "../../util/store";
import {getClearPhone, checkPhoneByLength} from "../../util/phone";
import API from "../../api";
import { SLUGS_TEXT_PAGES } from "../../constants/text-pages";
import './login.scss'


const Login = () => {
    const [alert, setAlert] = useReducer('alert');
    const location = useLocation()
    const address = location?.state?.address
    useLayout()
    const history = useHistory();
    const dispatch = useDispatch();
    const phone = useSelector(guestPhoneSelector);
    // const [phone, setPhone] = useState('');
    const [hasError, toggleError] = useState(false);
    const [agreement, setAgreement] = useState(true);
    const onToggleAgreement = useCallback(() => setAgreement((prev) => !prev), [])

    const setPhone = useCallback((value) => {
        dispatch(setGuest({ phone: value }))
    }, [dispatch])

    const onChange = useCallback((e) => {
        toggleError(false)
        setPhone(e.target.value)
    },[])

    const isDisabled = useMemo(() => {
        if (!agreement) {
            return true
        }
        const clearPhone = getClearPhone(phone)
        return !checkPhoneByLength(clearPhone)
    }, [phone, agreement])

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        if (isDisabled) {
            return
        }
        const clearPhone = getClearPhone(phone)
        if (!checkPhoneByLength(clearPhone)) {
            toggleError(true)
            return
        }
        API.post(
            '/user/check',
            { telephone: clearPhone},
            function (response) {
                if(response.message && !response.code){
                    setAlert({ danger: response.message });
                    dispatch(setGuest({ phone: clearPhone }))
                    history.push('/Code', { address })
                }
            },
            () => {},
            { isHideAlert: true }
        );
    }, [phone, history, isDisabled, address])

    return (
        <div className="login-page layout">
            <div className="layout__top layout__top--gray">
                <TopPage
                    onClick={history.goBack}
                    title="Вход"
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <div className="login-page__block">
                        <form onSubmit={onSubmit} method="post" disabled={isDisabled}>
                            <div className="login-page__field">
                                <Input
                                    id="telephone"
                                    name="telephone"
                                    mask="+7(\999)-999-99-99"
                                    onChange={onChange}
                                    value={phone}
                                    placeholder="+7(___)-___-__-__"
                                    isMask
                                    label="Номер телефона"
                                    error={hasError ? 'Заполните номер телефона' : null}
                                    inputMode="numeric"
                                    type="tel"
                                />
                            </div>
                            <div className="login-page__field">
                                <Button type="submit" className="btn btn-primary" disabled={isDisabled}>Получить код</Button>
                            </div>
                            <div className="login-page__field">
                                <Checkbox
                                    id="agree"
                                    value={agreement}
                                    onChange={onToggleAgreement}
                                >
                                    Я соглашаюсь на <Link to={`/pages/${SLUGS_TEXT_PAGES.SECURITY_POLICY}`}>обработку персональных данных</Link> и&nbsp;<Link to={`/pages/${SLUGS_TEXT_PAGES.OFFER}`}>принимаю оферту</Link>
                                </Checkbox>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
