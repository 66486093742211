import React, { useRef, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useSwipeable } from 'react-swipeable'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'
import './styles.scss'

const Modal = ({
  children,
  visible = false,
  onClose = () => {},
  btn = null,
  title = '',
  mod = null,
  onSubmit = false,
}) => {
  const top = useRef(0)

  useEffect(() => {
    const body = document.body
    if (visible) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }

    return () => body.style.overflow = 'visible';
  }, [visible])

  const handlers = useSwipeable({
    onSwiping: (e) => {
      e.event.stopPropagation();
      const el = document.querySelector('[data-modal-block]');
      if(!top.current) top.current = el.offsetTop;

      if(top.current > top.current + e.deltaY) return;

      el.style.top = (top.current + e.deltaY) + 'px';
    },
    onSwipedDown: (e) => {
      e.event.stopPropagation();
      const el = document.querySelector('[data-modal-block]');
      el.style.top = '';
      onClose();
    }
  });

  if (!visible) {
    return null
  }

  const modal = (
    <div className="modal-block__wrap">
      <div className="modal-block__back" onClick={onClose} />
      <div className={clsx('modal-block', mod && `modal-block--${mod}`)}>
        <div className="modal-block__body-wrap" data-modal-block="">
        <div className="modal-block__body">
          <div className="modal-block__top" {...handlers}>
          <div className="modal-block__swipe-icon" />
          </div>
          {title ? (
            <div className="grid-inner">
              <p className="modal-block__title">{title}</p>
            </div>
          ) : null}
          {onSubmit ? (
            <form onSubmit={onSubmit} className="modal-block__content">
              <div className="grid-inner">{children}</div>
            </form>
          ) : (
            <div className="modal-block__content">
              <div className="grid-inner">{children}</div>
            </div>
          )}

          {btn ? (
            <div className="modal-block__bottom">
              <div className="grid-inner">
                <Button onClick={btn.onClick} variant="primary">{btn.text}</Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      </div>
    </div>
  )

  return createPortal(modal, document.body)
}

export default Modal
