import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../../components/header';
import CatalogItem from '../../components/catalog-item'
import CartBtn from '../../components/cart-btn'
import BtnBack from '../../components/btn-back'
import { HeadNested } from '../../components/head'
import { CHANGE_LAYOUT } from '../../constants/events'
import {defaultCategories, getSubCategories} from '../../query/categories'
import useBodyMod from '../../util/body-mod'
import useLayout from '../../util/layout'
import './styles.scss'

const Category = () => {
  useBodyMod('body-white')
  const history = useHistory()
  const location = useLocation()
  const [subcategories, setSubcategories] = useState({
    name: '',
    categories: defaultCategories
  })

  useLayout(subcategories)

  const listAlias = useMemo(() => {
    const list = location.pathname.replace('/Category/', '').split('/').filter(v => v)
    return list
  }, [location.pathname])

  useEffect(() => {
    const alias = listAlias[listAlias.length - 1]
    if (alias) {
      getSubCategories(
        alias,
        setSubcategories,
        () => history.replace('/404'),
        { useCache: true },
      )
    }
  }, [listAlias, history])

  return (
    <div className="layout category">
      <HeadNested page="category" name={subcategories.name} />
      <div className="layout__top">
        <Header />
        <div className="grid-inner">
          <div className="category__top">
            <BtnBack onClick={history.goBack} text={subcategories.name}/>
          </div>
        </div>
      </div>
      <div className="layout__body">
        <div className="grid-inner">
          <div className="category__list">
            {subcategories.categories.map((category) => (
              <CatalogItem key={category.id} data={category} base={location.pathname} />
            ))}
          </div>
        </div>
      </div>
      <div className="layout__bottom">
        <CartBtn />
      </div>
    </div>
  )
}

export default Category
