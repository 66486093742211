import React, { useEffect, useState, useRef, useCallback } from 'react'
import OrderProduct from '../order-product'
import Count from '../count'
import './styles.scss'

const CartProduct = ({
  data: product,
  cartData,
  setCartData,
}) => {
  const timer = useRef(null)
  const [quantity, setQuantity] = useState(product.quantity);

  useEffect(()=>{
    let qty = cartData.products.find(item => item.productId === product.id);
    if(typeof qty !=='undefined' && quantity !== qty.quantity){
      setQuantity(qty.quantity);
    }
  }, []);

  const changeQuantity = useCallback((plus, isDelete = false) => {
    const qty = isDelete ? 0 : Math.max(plus ? quantity + 1 : quantity - 1, 0);
    setQuantity(qty);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setCartData({products: [{
        cartId: product.cartId,
        productId: product.productId,
        quantity: qty
      }]})
    }, 200);
  }, [quantity, timer, product.cartId, product.productId])

  const onDelete = useCallback(() => changeQuantity(false, true), [])

  return (
    <OrderProduct data={product} quantity={quantity} type="cart">
      <Count onChange={changeQuantity} quantity={quantity} />
    </OrderProduct>
  )
}

export default CartProduct
