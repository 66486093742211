import React, {useEffect, useState} from 'react'
import Header from '../../components/header'
import CatalogItem from '../../components/catalog-item'
import StatusOrder from '../../components/StatusOrder'
import CartBtn from '../../components/cart-btn'
import {defaultCategories, getCategories} from '../../query/categories'
import useBodyMod from '../../util/body-mod'
import useLayout from '../../util/layout'
import categoryXxxlImage from '../../img/products/xxxl.jpg'
import categoryDietImage from '../../img/products/diet.jpg'
import categoryComboImage from '../../img/products/combo.jpg'
import './styles.scss'

const addCategories = [
  {
    alias: 'XXXL',
    description: 'XXXL',
    id: 'XXXL',
    image: categoryXxxlImage,
    name: 'XXXL',
    parentId: '0',
    type: 'category',
    disabled: true,
  },
  {
    alias: 'Диета',
    description: 'Диета',
    id: 'diet',
    image: categoryDietImage,
    name: 'Диета',
    parentId: '0',
    type: 'category',
    disabled: true,
  },
  {
    alias: 'Комбо',
    description: 'Комбо',
    id: 'combo',
    image: categoryComboImage,
    name: 'Комбо',
    parentId: '0',
    type: 'category',
    disabled: true,
  },
]

const Catalog = () => {
  useLayout()
  useBodyMod('body-white')
  const [categories, setCategories] = useState(defaultCategories)

  useEffect(() => {
    getCategories((res) => {
      setCategories([...res, ...addCategories])
    }, () => {}, { useCache: true })
  }, [])
  return (
    <div className="layout catalog">
      <div className="layout__top">
        <Header/>
      </div>
      <div className="layout__body">
        <div className="grid-inner">
          <StatusOrder />
          <div className="catalog__list">
            {categories.map((category) => (
              <CatalogItem key={category.id} data={category} base="/Category" />
            ))}
          </div>
        </div>
      </div>
      <div className="layout__bottom">
        <CartBtn />
      </div>
    </div>
  )
}

export default Catalog
