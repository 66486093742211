import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthSelector } from '../store/selectors/user'

const GuestRoute = ({ component: Component, ...rest }) => {
    const isAuth = useSelector(isAuthSelector)

    return (
        <Route
            {...rest}
            render={props =>
                !isAuth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/Catalog', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default GuestRoute;
