const metaInfo = {
  default: {
    title: 'Тарелка - доставка еды по предпочтениям',
    description: 'Сайт доставки готовой еды'
  },
  lending: {
    title: 'Тарелка - настощая еда на каждый день с доставкой за 60 мин',
    description: 'Сайт доставки готовой еды'
  },
  main: {
    title: 'Тарелка - доставка еды по предпочтениям',
    description: 'Сайт доставки готовой еды'
  },
  map: {
    title: 'Карта | Тарелка',
    description: 'Выбор адреса на Сайте Тарелки'
  },
  lk: {
    title: 'Профиль | Тарелка',
    description: 'Профиль пользователя в личном кабинете Сайта Тарелки'
  },
  lkredact: {
    title: 'Редактирование профиля | Тарелка',
    description: 'Редактирование профиля пользователя в личном кабинете Сайта Тарелки'
  },
  address: {
    title: 'Редактирование адреса | Тарелка',
    description: 'Редактирование адреса Сайта Тарелки'
  },
  bonuses: {
    title: 'Бонусы | Тарелка',
    description: 'Бонусы на Сайте Тарелки'
  },
  orders: {
    title: 'Заказы | Тарелка',
    description: 'Заказы на Сайте Тарелки'
  },
  catalog: {
    title: 'Каталог продуктов | Тарелка',
    description: 'Каталог продуктов Сайта Тарелки'
  },
  category: {
    title: '{{name}} | Тарелка',
    description: 'Категория {{name}} Сайта Тарелки',
    defaultName: 'Категория',
  },
  subcategory: {
    title: '{{name}} | Тарелка',
    description: 'Категория {{name}} Сайта Тарелки',
    defaultName: 'Категория',
  },
  product: {
    title: '{{name}} | Тарелка',
    description: 'Блюдо {{name}} на Сайте Тарелки',
    defaultName: 'Блюдо',
  },
  cart: {
    title: 'Корзина | Тарелка',
    description: 'Корзина на Сайте Тарелки'
  },
  pages: {
    title: 'Материалы | Тарелка',
    description: 'Материалы на Сайте Тарелки'
  },
  page: {
    title: '{{name}} | Тарелка',
    description: '{{name}} на Сайте Тарелки',
    defaultName: 'Текстовая страница',
  },
  '404': {
    title: 'Страница не найдена | Тарелка',
    description: 'Страница не найдена'
  },
  error: {
    title: 'Что-то пошло не так | Тарелка',
    description: 'Что-то пошло не так на Сайте Тарелки'
  },
  login: {
    title: 'Вход в Личный Кабинет | Тарелка',
    description: 'Вход в личный кабинет Сайта Тарелки'
  },
  code: {
    title: 'Ввод кода из sms | Тарелка',
    description: 'Вход в личный кабинет Сайта Тарелки'
  },
  preferences: {
    title: 'Предпочтения | Тарелка',
    description: 'Предпочтения на Сайте Тарелки'
  },
  recommendations: {
    title: 'Советы | Тарелка',
    description: 'Советы на Сайте Тарелки'
  },
  orderaccepted: {
    title: 'Заказ принят | Тарелка',
    description: 'Заказ принят на Сайте Тарелки'
  },
}

export default metaInfo
