import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {defaultCategories, getMenu} from '../../query/categories'
import { getBackgroundColor } from '../../util/catalog/color'
import emptyImg from '../../img/empty/catalog-modal.png'
import './styles.scss'

const getImage = (image, isDefault = false) => (!isDefault && image) ? image : emptyImg

const FilterProduct = ({ active = null, setLoadingUI }) => {
  const history = useHistory()
  const [categories, setCategories] = useState(defaultCategories)

  useEffect(()=>{
    getMenu((res) => {
      const filteredList = res.filter(({ type }) => type === 'menu')
      setCategories(filteredList)
    }, () => {}, { useCache: true });
  }, [])

  const isLoading = useMemo(() => categories?.[0]?.default, [categories[0]])
  const onClick = useCallback((alias, type) => {
    setLoadingUI()
    const base = type === 'category' ?  '/Category/' : '/Subcategory/'
    history.replace(`${base}${alias}`)
  }, [history.replace, setLoadingUI])

  return (
    <div className={clsx('filter-product', isLoading && 'loadData')}>
      {categories.map(({ id, alias, name, image, type, default: isDefault = false }) => (
        <div className="filter-product__item" key={id}>
          <div
            onClick={() => onClick(alias, type)}
            className={clsx('filter-product__link', 'text', active === alias && 'active')}
          >
            <div
              className="filter-product__link-content"
              style={{ backgroundImage: `url('${getImage(image, isDefault)}')`, backgroundColor: getBackgroundColor({ default: isDefault, name }) }}>
              <p className="filter-product__name">{name}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FilterProduct
