import React from 'react';
import {Button, Form} from "react-bootstrap";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import "./lending.scss"
import { SLUGS_TEXT_PAGES } from "../../constants/text-pages"
import imgPhone from "../../img/lending/imgPhone.png"
import imgBerry from "../../img/lending/berry.png"
import imgFruit from "../../img/lending/fruit.png"
import imgLeaves from "../../img/lending/leaves.png"
import imgFonTop from "../../img/lending/fonGradientTop.png"
import circlePlateImg from "../../img/lending/circlePlate.png"
import plateImg1 from "../../img/lending/left-plate-img1.png"
import plateImg2 from "../../img/lending/left-plate-img2.png"
import plateImg3 from "../../img/lending/left-plate-img3.png"
import plateImg4 from "../../img/lending/left-plate-img4.png"
import plateImg5 from "../../img/lending/left-plate-img5.png"
import plateImg6 from "../../img/lending/right-plate-img1.png"
import plateImg7 from "../../img/lending/right-plate-img2.png"
import plateImg8 from "../../img/lending/right-plate-img3.png"
import imgAppPhone from "../../img/lending/imgAppPhone.png"
import fonBottom from "../../img/lending/fonBottom.png"
import plateImg from "../../img/lending/plateImg.png"
import socialIc1 from "../../img/lending/telegram.svg"
import socialIc2 from "../../img/lending/whatSap.svg"
import socialIc3 from "../../img/lending/insta.svg"
import arrowCircle from "../../img/lending/arrowCircle.svg"
import tryImg1 from "../../img/lending/tryImg1.png"
import tryImg2 from "../../img/lending/tryImg2.png"
import tryImg3 from "../../img/lending/tryImg3.png"
import tryImg4 from "../../img/lending/tryImg4.png"
import tryImg5 from "../../img/lending/tryImg5.png"
import appImgRight1 from "../../img/lending/appImg-right1.png"
import appImgRight2 from "../../img/lending/appImg-right2.png"
import appImgRight3 from "../../img/lending/appImg-right3.png"
import appImgRight4 from "../../img/lending/appImg-right4.png"
import appImgLeft1 from "../../img/lending/appImg-left1.png"
import appImgLeft2 from "../../img/lending/appImg-left2.png"
import appImgLeft3 from "../../img/lending/appImg-left3.png"
import appImgLeft4 from "../../img/lending/appImg-left4.png"
import boxAppAppleImg from "../../img/lending/boxAppAppleImg.png"
import controlImg01 from "../../img/lending/controlImg01.png"
import controlImg1 from "../../img/lending/controlImg1.png"
import controlImg2 from "../../img/lending/controlImg2.png"
import controlImg3 from "../../img/lending/controlImg3.png"
import controlImg4 from "../../img/lending/controlImg4.png"
import controlImg5 from "../../img/lending/controlImg5.png"
import controlImg6 from "../../img/lending/controlImg6.png"
import controlImg7 from "../../img/lending/controlImg7.png"
import controlImg8 from "../../img/lending/controlImg8.png"
import controlImg9 from "../../img/lending/controlImg9.png"
import controlImg10 from "../../img/lending/controlImg10.png"
import controlImg11 from "../../img/lending/controlImg11.png"
import controlImg12 from "../../img/lending/controlImg12.png"
import controlImg from "../../img/lending/controlImg.png"

const Lending = (props) => {
    return (
        <div className="lending-page">
            <div className="orderBlock">
                <div className="orderBlock-inf">
                    <div className="orderBlock-plateImg">
                        <img src={plateImg} alt=""/>
                    </div>
                    <img src={imgBerry} alt="" className="berry" />
                    <div className="logo">ТАРЕЛКА</div>
                    <div className="title"><strong>настощая</strong> еда на каждый день с доставкой за 30 мин</div>
                    <div className="subtitle">
                        Еда из доставки должна быть вкусной, полезной
                        и разнообразной. Мы придумали <strong>Тарелку</strong> -  доставку с гибким меню и сотнями сочетаний на любой вкус
                    </div>
                    <button className="btn btn-primary">Заказать</button>
                    <img src={imgFruit} alt="" className="fruit" />
                </div>
                <div className="down">
                    <div className="down-arrow"/>
                    <div className="down-arrow"/>
                    <div className="down-arrow"/>
                </div>
                <img src={imgLeaves} alt="" className="leaves"/>
                <div className="imgPhone">
                    <img src={imgPhone} alt=""/>
                </div>
            </div>
            <div className="fonTop-box">
                <img src={imgFonTop} alt="" className="fonTop"/>
                <div className="fonTop-radius">
                    <div className="left-plate-img">
                        <img src={plateImg1} className="img1" alt=""/>
                        <img src={plateImg2} className="img2" alt=""/>
                        <img src={plateImg3} className="img3" alt=""/>
                        <img src={plateImg4} className="img4" alt=""/>
                        <img src={plateImg5} className="img5" alt=""/>
                    </div>
                    <div className="right-plate-img">
                        <img src={plateImg6} className="img1" alt=""/>
                        <img src={plateImg7} className="img2" alt=""/>
                        <img src={plateImg8} className="img3" alt=""/>
                    </div>
                </div>
            </div>
            <div className="methodology">
                <div className="title">
                    Все плюсы домашней еды
                    по методология гарвардской тарелки
                </div>
                <div className="subtitle"><strong>Выбирайте на свой вкус</strong> из привычных блюд и разных сочетний</div>
                <div className="box-circle">
                    <div className="circle-plate">
                        <ul className="box left-top">
                            <li>Мясо/ рыба и белок</li>
                            <li>Творог</li>
                            <li>Яичница</li>
                            <li>Сырники</li>
                        </ul>
                        <ul className="box right-top">
                            <li>Блинчики</li>
                            <li>Сендвичи и врапы</li>
                            <li>Гарниры</li>
                        </ul>
                        <img src={circlePlateImg} alt=""/>
                        <ul className="box left-bottom">
                            <li>Фрукты</li>
                            <li>Микс фруктов</li>
                            <li>Овощи</li>
                            <li>Салаты</li>
                        </ul>
                        <ul className="box right-bottom">
                            <li>Рис</li>
                            <li>Каша</li>
                            <li>Макароны</li>
                        </ul>
                        <ul className="box center-bottom">
                            <li>Масло</li>
                            <li className="point"/>
                            <li>Соусы</li>
                            <li className="point"/>
                            <li>Специи</li>
                            <li className="point"/>
                            <li>Топпинги</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="box-control">
                <div className="controlImg-left">
                    <img src={controlImg01} className="controlImg01" alt=""/>
                    <img src={controlImg1} className="controlImg1" alt=""/>
                    <img src={controlImg2} className="controlImg2" alt=""/>
                    <img src={controlImg3} className="controlImg3" alt=""/>
                    <img src={controlImg4} className="controlImg4" alt=""/>
                    <img src={controlImg5} className="controlImg5" alt=""/>
                    <img src={controlImg6} className="controlImg6" alt=""/>
                </div>
                <div className="controlImg-right">
                    <img src={controlImg7} className="controlImg1" alt=""/>
                    <img src={controlImg8} className="controlImg2" alt=""/>
                    <img src={controlImg9} className="controlImg3" alt=""/>
                    <img src={controlImg10} className="controlImg4" alt=""/>
                    <img src={controlImg11} className="controlImg5" alt=""/>
                    <img src={controlImg12} className="controlImg6" alt=""/>
                </div>
                <img src={controlImg} alt="" className="controlImg"/>
                <div className="title"><strong>Меню, еда и готовка под контролем</strong> - ошибиться не получиться</div>
                <div className="control-flex card">
                    <div className="control-item">
                        <div className="control-item-name">МЕНЮ</div>
                        <div className="title">Фаст фуд</div>
                        <div className="box">
                            <div className="name" data-text="Фаст фуд">МЕНЮ</div>
                            <div className="text" >Однообразное моно меню </div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Тарелка">ЕДА</div>
                            <div className="text" >Жирная и калорийная еда
                                Консерванты, усилетели вкуса</div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Экстрим ЗОЖ">ГОТОВКА</div>
                            <div className="text" >Сильная жарка, фритюры </div>
                        </div>
                    </div>
                    <div className="control-item">
                        <div className="control-item-name">ЕДА</div>
                        <div className="title">Тарелка</div>
                        <div className="box">
                            <div className="name" data-text="Фаст фуд">МЕНЮ</div>
                            <div className="text" >Разнообразное и гибкое  </div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Тарелка">ЕДА</div>
                            <div className="text" >Качественая и привычная еда
                                с хорошими соусами, сециями
                                и заправками
                            </div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Экстрим ЗОЖ">ГОТОВКА</div>
                            <div className="text" >Духовка, гриль, пар, сувид
                                и минимальное воздействие
                            </div>
                        </div>
                    </div>
                    <div className="control-item">
                        <div className="control-item-name">ГОТОВКА</div>
                        <div className="title">Экстрим ЗОЖ</div>
                        <div className="box">
                            <div className="name" data-text="Фаст фуд">МЕНЮ</div>
                            <div className="text" >Жесткие диеты и ограничения </div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Тарелка">ЕДА</div>
                            <div className="text" >Зачастую Редкая и экзотическая
                                Пресные и непонятные вкусы
                            </div>
                        </div>
                        <div className="box">
                            <div className="name" data-text="Экстрим ЗОЖ">ГОТОВКА</div>
                            <div className="text" >Пар, сырая </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="box-app">
                <img src={boxAppAppleImg} className="box-appAppleImg" alt=""/>
                <div className="appImg-left">
                    <img src={controlImg11} alt=""/>
                    <img src={appImgLeft2} alt=""/>
                    <img src={appImgLeft3} alt=""/>
                    <img src={appImgLeft4} alt=""/>
                </div>
                <div className="appImg-right">
                    <img src={appImgRight1} className="appImg1" alt=""/>
                    <img src={appImgRight2} className="appImg2" alt=""/>
                    <img src={appImgRight3} className="appImg3" alt=""/>
                    <img src={appImgRight4} className="appImg4" alt=""/>
                </div>
                <div className="title">Приложение <strong>на каждый день</strong> - персонально и просто</div>
                <div className="box-app__body">
                    <div className="text-left">
                        <span className="name">Персональный</span>
                        на каждый день
                    </div>
                    <div className="imgAppPhone">
                        <img src={imgAppPhone} alt=""/>
                    </div>
                    <div className="text-right">
                        <span className="name">Фильтр меню</span>
                        профиль
                    </div>
                </div>
                <img src={fonBottom} className="fonBottom" alt=""/>
            </div>

            <div className="map">
                <div className="balloon"><strong>Работаем в этой зоне доставки</strong>,
                    чтобы привозить заказ быстро</div>
                <div className="text-inf">Планируем расширять
                    зону доставки</div>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab18eeaf28fcde032d3f5e4e3b8dc7b4d9bedbbb55c384456ec82d57a25f8ae21&amp;source=constructor"
                    width="100%" height="100%" frameBorder="0"></iframe>
            </div>

            <div className="try">
                <img src={tryImg2} alt="" className="imgApple"/>
                <img src={tryImg3} alt="" className="imgOrange"/>
                <img src={tryImg4} alt="" className="imgBerry1"/>
                <img src={tryImg5} alt="" className="imgBerry2"/>
                <div className="try-title">
                    <div className="title">Попробуй приложение</div>
                    <div className="logo">ТАРЕЛКА</div>
                    <img src={tryImg1} alt="" className="try-img" />
                </div>
                <Form action="#" className="form">
                    <div className="text">Оставь свои данные и <strong>получи скидку 200 ₽ на первый заказ</strong></div>
                    <Form.Group className="mb-2">
                        <Form.Control type="text" placeholder="Ваше имя" />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <InputMask
                            mask="+7(\999)-999-99-99"
                            onChange={props.onChange}
                            value={props.value}
                            className="form-control"
                            placeholder="+7(___)-___-__-__"
                        />
                    </Form.Group>
                    <Button variant="primary">Получить промокод</Button>
                </Form>

                <div className="telega">
                    <div className="text">Присоединяйся к нам в Телеграмм</div>
                    <img src={arrowCircle} alt=""/>
                    <Link to="#" className="btn btn-blue">Перейти в канал</Link>
                </div>
            </div>

            <footer>
                <div className="left">
                    <div className="img">
                        <img src={plateImg} alt=""/>
                    </div>
                    <div className="text">
                        <div className="name">Доставка еды в Москве</div>
                        <Link to={`/pages/${SLUGS_TEXT_PAGES.SECURITY_POLICY}`} className="link">Политика конфиденциальности</Link>
                    </div>
                </div>
                <div className="social">
                    <Link to="#" className="ic" style={{background: 'linear-gradient(180deg, #59B4FA 0%, #2F89CE 100%)'}}>
                        <img src={socialIc1} alt=""/>
                    </Link>
                    <Link to="#" className="ic" style={{background: 'linear-gradient(180deg, #6EE984 0%, #48C95F 100%)'}}>
                        <img src={socialIc2} alt=""/>
                    </Link>
                    <Link to="#" className="ic" style={{background: 'linear-gradient(43.94deg, #EEA054 16.16%, #D64763 50.11%, #B62A99 83.68%)'}}>
                        <img src={socialIc3} alt=""/>
                    </Link>
                </div>
                <Link to="#" className="link">Задать вопрос</Link>
            </footer>

        </div>
    );
};

export default Lending;
