import API from "../api";
import {useReducer} from "../util/store";
const [alert, setAlert] = useReducer('alert');

export const getCart = (callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/cart',
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        },
        config
    );
}

export const setCart = (data, callback, failCallback = () => {}, config = {}) => {
    API.post(
        '/cart',
        data,
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else if(response.products) {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        },
        config,
    );
}
