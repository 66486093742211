import React, {useState} from 'react';
import {useReducer} from "../util/store";

const Message = (props) => {
    let initState = {
        'danger': '',
        'info': '',
        'success': ''
    };
    const [alert, setAlert] = useReducer('alert', 'Message', initState);

    const clear = () => {
        setAlert(initState);
    }

    let message = alert.danger || alert.info || alert.success;
    let css = 'd-none';
    if(alert.info){
        css = 'alert-info';
    }
    if(alert.danger){
        css = 'alert-danger';
    }
    if(alert.success){
        css = 'alert-success';
    }

    if(message) {
        setTimeout(() => {
            clear();
        }, 6000);
    }

    return (
        <div className={"alert " + css} onClick={clear}>
            {message}
        </div>
    );
};

export default Message;
