import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import metaInfo from './data'

const urlLogo = (typeof API_URL !== 'undefined' ? API_URL : '') + '/image/logo.png'
const keyAnalyticsCart = (typeof API_KEY_DMP_ONE_CART !== 'undefined' ? API_KEY_DMP_ONE_CART : null )

const useWindowPathname = (pathname) => useMemo(() => {
  const winLocation = window.location
  return `${winLocation.origin}${winLocation.pathname}`
}, [pathname])

const getKeyByLocation = (pathname = '') => {
  if (pathname === '/') {
    return 'main'
  }

  const list = pathname.split('/').filter((v) => v)
  const key = list[0]
  if (key === 'pages' && list[1]) {
    return 'page'
  }

  if (key) {
    return key.toLowerCase()
  }

  return 'default'
}

const Block = ({ description = '', title = '', pathname = '', analyticsCart = false }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={urlLogo}/>
      <meta property="og:url" content={pathname} />
      <meta property="og:site_name" content="Tarelka" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={urlLogo} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta itemProp="image" content={urlLogo} />
      {analyticsCart ? (
        <script src={`https://dmp.one/sync?stock_key=${keyAnalyticsCart}`} async charset="UTF-8" />
      ) : null}
    </Helmet>
  )
}

const Head = () => {
  const [data, setData] = useState(metaInfo.default)
  const [analyticsCart, toggleAnalyticsCart] = useState(false)
  const location = useLocation()
  const winPathname = useWindowPathname(location.pathname)

  useEffect(() => {
    const key = getKeyByLocation(location.pathname)
    setData(metaInfo[key] || metaInfo.default)
    toggleAnalyticsCart(key === 'cart' && !!keyAnalyticsCart)
  }, [location.pathname])

  return (
    <Block
      title={data.title}
      description={data.description}
      pathname={winPathname}
      analyticsCart={analyticsCart}
    />
  )
}

export const HeadNested = ({ page = '', name }) => {
  const location = useLocation()
  const winPathname = useWindowPathname(location.pathname)

  const data = useMemo(() => {
    const obj = metaInfo[page]
    if (!obj) {
      return metaInfo.default
    }

    const renderName = name.length ? name : obj.defaultName

    return {
      ...obj,
      title: obj.title.replaceAll('{{name}}', renderName),
      description: obj.description.replaceAll('{{name}}', renderName),
    }
  }, [page, name])

  return (
    <Block
      title={data.title}
      description={data.description}
      pathname={winPathname}
    />
  )
}

export default Head
