import React, { useMemo } from 'react'
import clsx from 'clsx'
import OrderProduct from '../order-product'
import progressImg1 from '../../img/order-progress/progress-1.png'
import progressImg2 from '../../img/order-progress/progress-2.png'
import progressImg3 from '../../img/order-progress/progress-3.png'
import progressImg4 from '../../img/order-progress/progress-4.png'
import { stepsUIByStatuses } from '../../constants/statuses'
import './styles.scss'

const listSteps = [
  { step: 1, image: progressImg1 },
  { step: 2, image: progressImg2 },
  { step: 3, image: progressImg3 },
  { step: 4, image: progressImg4 },
]

const OrderAdditionalInfo = ({ data }) => {
  const isCancelStatus = useMemo(() => data.statusId > 6, [data.statusId])
  const currentStep = useMemo(() => {
    if (isCancelStatus) {
      return 0
    }

    return stepsUIByStatuses[data.statusId]
  }, [isCancelStatus, data.statusId])

  const style = useMemo(() => {
    const size = currentStep ? 33 * Math.max(currentStep - 1, 0) : 0
    return { width: `calc(${Math.min(size, 100)}% + 13px)` }
  }, [currentStep])

  return (
    <div className="orders-card__dropdown">
      {!isCancelStatus ? (
        <div className="order-progress" data-status={currentStep}>
          {listSteps.map(({ step, image }) => (
            <div key={step} className={clsx('order-progress__img', currentStep >= step && 'active')}>
              <img src={image} alt="" className="d-none"/>
            </div>
          ))}
          <div className="order-progress__line" style={style}/>
        </div>
      ) : null}

      {data.statusComment ? (
        <p className="order-status-comment text">{data.statusComment}</p>
      ) : null}

      <div className="orders-card__products">
        {data.products.map((product, index) => (
          <OrderProduct data={product} quantity={product.quantity} key={`${product.product_id}_${index}`} type="order"/>
        ))}
      </div>
    </div>
  )
}

export default OrderAdditionalInfo
