const isProd = typeof IS_PROD !== 'undefined' && IS_PROD === 'true'

export const SLUGS_TEXT_PAGES = isProd ? {
  SECURITY_POLICY: '5',
  OFFER: '7',
  PROMO: '8',
} : {
  SECURITY_POLICY: '5',
  OFFER: '7',
  PROMO: '8',
}
